import React from 'react';

function PrivacyPolicy() {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
            <p className="mb-2">
                This Aeon Privacy Policy (&quot;Privacy Policy&quot;) outlines our practices regarding the collection, storage, and use of personal information and other data collected from users when accessing the Aeon website located at www.aeonai.com (&quot;Site&quot;). The Site is owned and operated by Aeon AI LLC (&quot;Aeon,&quot; &quot;we,&quot; &quot;our,&quot; or &quot;us&quot;).
            </p>
            {/* ... Add more paragraphs and sections as needed ... */}
            <h2 className="text-2xl font-bold mt-4 mb-2">Background</h2>
            <p className="mb-2">
                This Privacy Policy addresses how we handle personal information collected when you: (a) access or use the Site; (b) access, view, and/or download text, images, photos, video, audio, and other content on the Site (&quot;Content&quot;); and (c) register as a member (&quot;Member&quot;) of the Site, enabling features such as creating a personal profile, accessing member directories, participating in events, and utilizing member-exclusive offers.
            </p>
            {/* ... Continue adding content for each section of your privacy policy ... */}

            {/* Include all sections and subsections of the policy */}
            {/* Example: Your Privacy Rights, Personal Information Collected, Use of Personal Information, etc. */}

            {/* Ensure to format each section for readability and clarity */}
        </div>
    );
}

export default PrivacyPolicy;


