import React, { useState } from 'react';

function FAQ() {
    const [openFAQ, setOpenFAQ] = useState(null);

    const toggleFAQ = index => {
        setOpenFAQ(openFAQ === index ? null : index);
    };

    const faqData = [
        {
            question: 'What is Aeon?',
            answer: 'Aeon is an exlcusive membership community for leaders in AI. Founders, investors, and reseachers share knowledge.'
        },
        {
            question: 'Who\'s already in the community?',
            answer: 'We have venture-backed, and exited founders, AI-centric investors like angels, micro funds, and VC, as well as world class researchers from top tier institutions.'
        },
        {
            question: 'Are there expectations?',
            answer: 'Members are expected to actively contribute to discussions, share their knowledge and experiences, and maintain a respectful and collaborative environment.'
        },
        {
            question: 'What will I get out of it?',
            answer: 'Membership in Aeon offers networking opportunities, access to exclusive AI insights, and the chance to collaborate with peers and experts in the field.'
        },
        {
            question: 'Why is Aeon private?',
            answer: 'Aeon is private to ensure high-quality engagement and content. This exclusivity allows us to bring together dedicated AI leaders, fostering meaningful discussions and valuable connections within the community.'
        },
        // Add more FAQs as needed
    ];

    return (
        <div className='text-white p-20 pt-20 pb-40 pl-32'>
            <h1 className='text-7xl text-white mb-6'>Frequently Asked Questions</h1>
            <div>
                {faqData.map((faq, index) => (
                    <div key={index} className='mb-4 border-b border-gray-200 pb-4'>
                        <button 
                            onClick={() => toggleFAQ(index)} 
                            className='flex justify-between items-center w-full text-3xl text-white'
                        >
                            {faq.question}
                            <span className='text-white'>{openFAQ === index ? '▲' : '▼'}</span>
                        </button>
                        {openFAQ === index && <p className='text-white pt-6 text-xl mt-2'>{faq.answer}</p>}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQ;



