import React from 'react';

function FeaturesSection() {
    return (
        <div className="py-8 px-1 md:px-0">
            <div className="container mx-auto max-w-full">
                <h2 className="text-7xl text-white mb-6">Forget the hype. Aeon members are leaders in AI, pushing boundaries and building things for scale.</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4 mt-10">
                    {/* Feature 1 */}
                    <div className="text-white feature-item p-4 border border-green-500 rounded-lg text-2xl">
                        <p>The Aeon community prioritizes founders building real AI companies.</p>
                    </div>
                    {/* Feature 2 */}
                    <div className="text-white text-2xl feature-item p-4 border border-green-500 rounded-lg">
                        <p>We have angel investors and VCs writing real checks in the space.</p>
                    </div>
                    {/* Feature 3 */}
                    <div className="text-white text-2xl feature-item p-4 border border-green-500 rounded-lg">
                        <p>World-class engineers and researchers from elite institutions.</p>
                    </div>
                </div>
            </div>
        </div> 
    );
}

export default FeaturesSection;



