import React from 'react';
import { Link } from 'react-router-dom';

function About() {
    return (
        <div className="text-white p-20 pt-40 pb-40">
            <div className="mx-auto max-w-5xl">
                <h1 className="text-7xl mb-8">What is Aeon?</h1>
                <p className="text-2xl font-semi-bold mb-10">
                    Aeon is an invite-only community for leaders in AI, encompassing founders, investors, and researchers. It offers direct access to the industry&apos;s top minds, providing a competitive edge in this rapidly evolving field.
                </p>
                <p className="text-2xl font-semi-bold mb-10">
                    For founders, Aeon is a hub for practical knowledge and networking. Investors gain insights into the latest AI trends for informed decisions. Researchers can collaborate with industry practitioners, turning academic theories into real-world applications.
                </p>
                <p className="text-2xl font-semi-bold mb-10">
                    Being part of Aeon means more than joining a community; it&apos;s about actively shaping the future of AI.
                </p>
                <div className="mt-8">
                    <Link to="/apply-now" className="bg-black hover:bg-white hover:text-black border border-white text-white py-2 px-8 text-xl rounded-lg">
                        Apply Now
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default About;














