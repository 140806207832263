import React, { useEffect } from 'react';

function Apply() {
    useEffect(() => {
        // Create a script element
        const script = document.createElement('script');

        // Set the script's src attribute
        script.src = '//embed.typeform.com/next/embed.js';
        
        // Append the script to the body
        document.body.appendChild(script);
    }, []); // The empty array ensures this effect runs once after initial render

    return (
        <div>
            <div data-tf-live="01HJ4318Y1243ZFSBSRSN4Y12V"></div>
        </div>
    );
}

export default Apply;