import React from 'react';
import { Link } from 'react-router-dom';

function HeroSection() {
    return (
        <div className="text-white p-20 pt-40 pb-40">
            {/* Apply additional left padding on desktop screens */}
            <div className="mx-auto max-w-5xl md:pl-">
                <h1 className="text-7xl mb-8">Join the private network for leaders in AI.</h1>
                <br />
                <p className="text-2xl font-semi-bold max-w-5xl mb-10">Aeon is a membership community of founders, investors, and researchers.</p>
                <br />
                <Link to="/explore-membership" className="bg-black hover:bg-white hover:text-black border border-white text-white py-2 px-8 text-xl rounded-lg">
                    Learn More
                </Link>
            </div>
        </div>
    );
}

export default HeroSection;

