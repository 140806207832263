import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="text-white border-t p-4">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center py-2">
                <div className="flex flex-col md:flex-row items-center mb-4 md:mb-0">
                    <p className="text-sm md:text-lg mb-2 md:mb-0 mr-4">© 2023 Aeon.</p>
                    <Link to="/explore-membership" className="hover-underline px-2 py-1 text-sm md:text-lg">Explore Membership</Link>
                    <Link to="/about" className="hover-underline px-2 py-1 text-sm md:text-lg">About</Link>
                    <a href="mailto:info@joinaeon.com" className="hover-underline px-2 py-1 text-sm md:text-lg">Contact</a>
                    <Link to="/faq" className="hover-underline px-2 py-1 text-sm md:text-lg">FAQ</Link>
                </div>
                <div>
                    <Link to="/privacy-policy" className="px-2 py-1 text-sm md:text-lg">Privacy Policy</Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;









