import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import Footer from './components/Footer';
import ExploreMembership from './components/ExploreMembership';
import Apply from './components/Apply';
import About from './components/About';
import Contact from './components/Contact';
import FAQ from './components/FAQ';
import Login from './components/Login';
import PrivacyPolicy from './components/PrivacyPolicy';
import LogosDisplay from './components/LogosDisplay';
import JoinBelow from './components/JoinBelow';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen mx-auto max-w-7xl"> {/* Flex container for the whole app */}
        <Navbar />
        <div className="flex-grow"> {/* Content area that grows to push the footer down */}
          <Routes>
            <Route path="/" element={
              <>
                <HeroSection />
                <FeaturesSection />
                <LogosDisplay />
                <JoinBelow />
              </>
            }/>
            <Route path="/explore-membership" element={<ExploreMembership />} />
            <Route path="/apply" element={<Apply />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/login" element={<Login />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;







