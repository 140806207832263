import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    return (
        <nav className="flex flex-col md:flex-row items-center justify-between pl-1 pr-4 py-4">
            {/* Left section for the title and navigation links */}
            <div className="flex flex-col md:flex-row items-center">
                <Link to="/" className="text-2xl md:text-3xl text-white font-bold mr-2 md:mr-4 mb-2">aeon</Link>
                
                {/* Navigation links */}
                <div className="flex flex-wrap mt-2 md:mt-0">
                    <Link to="/explore-membership" className="text-white px-2 py-1 text-sm md:text-lg hover-underline">Explore Membership</Link>
                    <Link to="/about" className="text-white px-2 py-1 text-sm md:text-lg hover-underline">About</Link>
                    <a href="mailto:info@joinaeon.com" className="text-white px-2 py-1 text-sm md:text-lg hover-underline">Contact</a>
                    <Link to="/faq" className="text-white px-2 py-1 text-sm md:text-lg hover-underline">FAQ</Link>
                </div>
            </div>

            {/* Right section for buttons */}
            <div className="mt-4 md:mt-0">
                <Link to="/apply" className="bg-black font-bold hover:bg-white hover:text-black border border-white text-white py-2 px-3 m-1 rounded-lg text-sm md:text-lg">
                    Apply
                </Link>
                <a href="https://slack.com/signin#/signin" className="bg-black hover:bg-white hover:text-black border border-white text-white py-2 px-3 m-1 rounded-lg text-sm md:text-lg" target="_blank" rel="noopener noreferrer">
                    Login
                </a>
            </div>
        </nav>
    );
}

export default Navbar;











