import React from 'react';

function LogosDisplay() {
    const logos = [
        { src: './openai.png', alt: 'OpenAI logo' },
        { src: '/stanford-new.png', alt: 'Stanford logo' },
        { src: '/a16z.png', alt: 'a16z logo' },
        { src: '/yc.png', alt: 'YC logo' },
        // Add more logos as needed
    ];

    return (
        <div className="logos-display-container mx-auto p-4 text-white pt-40 flex flex-col justify-center items-center">
            <p className="text-center text-2xl mb-4">Our members are from top tier organizations</p>
            <div className="flex justify-center items-center flex-wrap">
            {logos.map((logo, index) => (
                <div key={index} className="logo-container">
                    <img src={logo.src} alt={logo.alt} className="logo" />
                </div>
            ))}
            </div>
        </div>
    );
}

export default LogosDisplay;




