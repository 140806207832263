import React, { useState, useEffect } from 'react';

function JoinBelow() {
    const [isTypeformLoaded, setIsTypeformLoaded] = useState(false);

    useEffect(() => {
        // Check if Typeform script is already loaded
        if (!document.querySelector('script[src="//embed.typeform.com/next/embed.js"]')) {
            const script = document.createElement('script');
            script.src = '//embed.typeform.com/next/embed.js';
            script.onload = () => setIsTypeformLoaded(true); // Update state when script is loaded
            document.body.appendChild(script);
        } else {
            // Script already loaded
            setIsTypeformLoaded(true);
        }
    }, []);

    const openTypeForm = () => {
        if (isTypeformLoaded && window.tf) {
            window.tf.createOverlay('01HJ4318Y1243ZFSBSRSN4Y12V');
        }
    };

    return (
        <div className="text-white text-center p-20 pt-40 pb-40">
            <div className="mx-auto max-w-5xl">
                <h1 className="text-7xl mb-8">We&apos;d love to have you.</h1>
                <br /><br />
                <button onClick={openTypeForm} className="bg-black hover:bg-white hover:text-black border border-white text-white py-2 px-8 text-xl rounded-lg">
                    Apply Now
                </button>
            </div>
        </div>
    );
}

export default JoinBelow;

