import React from 'react';
import { Link } from 'react-router-dom';

function ExploreMembership() {
    return (
        <div className="text-white p-20 pt-20 pb-40">
            <div className="mx-auto max-w-5xl">
                <h1 className="text-7xl mb-8">Get connected with the brightest minds in AI</h1>
                <h2 className="text-3xl font-bold mb-6">Why Join Aeon?</h2>
                <p className="text-2xl font-semi-bold mb-10">
                    Aeon isn&apos;t just a community; it&apos;s the epicenter of AI innovation. A platform where the brightest minds in AI converge to shape the future. If you&apos;re serious about AI, Aeon is where you need to be.
                </p>

                <h2 className="text-3xl font-bold mb-6">Exclusive Community Benefits</h2>
                <ul className="list-disc text-2xl font-semi-bold ml-8 mb-10">
                    <li>Access to a network of AI leaders, founders, and innovators.</li>
                    <li>Engage in meaningful discussions and recorded chats.</li>
                    <li>Participate in community-driven Slack conversations.</li>
                </ul>

                <h2 className="text-3xl font-bold mb-6">Who is Aeon For?</h2>
                <p className="text-2xl font-semi-bold mb-10">
                    Ideal for AI founders, engineers, researchers, and investors. Aeon is for those at the forefront of AI development, looking to make significant advancements in the field.
                </p>

                <div className="mt-8 text-center">
                    <Link to="/apply" className="bg-black hover:bg-white hover:text-black text-white border border-white py-2 px-8 text-xl rounded-lg">
                        Apply for Membership
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ExploreMembership;








